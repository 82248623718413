import React, { useState, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { InView } from 'react-intersection-observer';
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'
import GeneralButton from "../generalButton";

const AreasTitleText = ({ data, mobile }) => {

    const [isVisible, setIsVisible] = useState(false)
    const { language } = useI18next()

    const {
        translateUrl // (url(, locale)) => translatedUrl
    } = useContext(TranslateUrlsContext)

    return (
        <InView as="div" threshold={mobile ? "0.2" : "0.4"} onChange={(inView) => setIsVisible(inView)}
            className="container-margin container-areas-title-text">
            <Row >
                <Col sm="4" md="4" lg="4">
                    <p className="fs-6 ILightBeta black" style={{
                        transform: isVisible ? "none" : "translateX(-200px)",
                        opacity: isVisible ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s"
                    }}
                        dangerouslySetInnerHTML={{ __html: data.areas }} />

                </Col>
                <Col sm="6" md="6" lg="6"></Col>
                <Col sm="4" md="4" lg="4"></Col>
            </Row >
            <Row >
                <Col sm="4" md="4" lg="4"></Col>
                <Col sm="6" md="6" lg="6" >
                    <h2 className="fs-1 ILightBeta black mb-4" style={{
                        transform: isVisible ? "none" : "translateY(200px)",
                        opacity: isVisible ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s"
                    }} dangerouslySetInnerHTML={{ __html: data.title }} />

                    <p className="fs-6 ILightBeta black" style={{
                        transform: isVisible ? "none" : "translateY(200px)",
                        opacity: isVisible ? 1 : 0,
                        transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.6s"

                    }} dangerouslySetInnerHTML={{ __html: data.text }}
                    />
                    {data.btn &&

                        <div className=" text-left white mt-5 fs-4 text-uppercase" style={{
                            transform: isVisible ? "none" : "translateY(200px)",
                            opacity: isVisible ? 1 : 0,
                            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.8s"
                        }}>
                            <GeneralButton text={data.btn.text} url={translateUrl(data.btn.link, language)} center={false} name="ml-0 mr-auto" />
                        </div>
                    }
                </Col>
                <Col sm="4" md="4" lg="4"></Col>
            </Row >
        </InView >
    );
}
export default AreasTitleText;