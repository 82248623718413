import * as React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'


import Layout from '../components/layout/layout'
import MenuGlobal from '../components/menu'

import '../sass/services.scss';
import TopParallax from "../components/services/topParallax";
import AreasTitleText from "../components/services/areasTitleText";
import FullWidthImage from "../components/services/fullWidthImage";

const ServiceTemplate = ({ pageContext, location }) => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint();

  const mobile = breakpoints.mobile


  return (
    <Layout mobile={mobile} post={null} data={t("global", { returnObjects: true })} page={t("portfolio", { returnObjects: true }).page} seo={pageContext.seo}>
      {mobile ?
        <>
          <MenuGlobal noFilter location={location} portfolioIndividual
            global={t("global", { returnObjects: true })} centered={true} mobile noScroll />


          <div className="container-scroll-change-bg" style={{ background: 'linear-gradient(180deg, #EDEDED 0%, #F8F4EF 100%)' }}>

            <TopParallax data={pageContext.top} name={pageContext.name} />

            <AreasTitleText data={pageContext.resume} mobile />

            {pageContext.extraInfo &&
              <>
                <a href={pageContext.extraInfo.link} target="_blank" rel="noreferrer" aria-label= {pageContext.extraInfo.alt}>
                  <FullWidthImage image={pageContext.extraInfo.image} alt={pageContext.extraInfo.alt} id={pageContext.extraInfo.id} location={location} />
                </a>
                <AreasTitleText data={pageContext.extraInfo.titleText} mobile />
              </>
            }

          </div>


        </>
        :
        <>
          <MenuGlobal noFilter location={location} portfolioIndividual
            global={t("global", { returnObjects: true })} centered={true} noScroll>


            <div className="container-scroll-change-bg" style={{ background: 'linear-gradient(180deg, #EDEDED 0%, #F8F4EF 100%)' }}>

              <TopParallax data={pageContext.top} name={pageContext.name} />

              <AreasTitleText data={pageContext.resume} />

              {pageContext.extraInfo &&
                <>
                  <a href={pageContext.extraInfo.link} target="_blanks"  rel="noreferrer" aria-label= {pageContext.extraInfo.alt}>
                    <FullWidthImage image={pageContext.extraInfo.image} alt={pageContext.extraInfo.alt} id={pageContext.extraInfo.id} location={location} />
                  </a>
                  <AreasTitleText data={pageContext.extraInfo.titleText} />
                </>
              }

            </div>
          </MenuGlobal>
        </>
      }


    </Layout>
  )
}



export default ServiceTemplate


export const pageQuery = graphql`
    query($language: String!) {
      locales: allLocale(filter: {ns: {in: ["global", "portfolio"]}, language: {eq: $language}}) {
      edges {
      node {
      ns
          data
          language
        }
      }
    }
    allFile {
      edges {
        node {
          relativePath
          publicURL
        }
      }
    }
  }
`